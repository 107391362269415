import { Checkbox, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { GridRenderCellParams } from '@mui/x-data-grid';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CustomMultiLineEdit from '../../components/CustomMultiLineEdit/CustomMultiLineEdit';
import { renderCellTooltip } from '../Resource/ResourceAllocationHistoryConfig';
import { sortAlphaNumeric } from '../../utils/utils';

const dynamicColumns: any = [];

const tableRowTotalField: any = [];

const columns = [
  {
    field: 'employeeId',
    headerName: 'Emp. ID',
    headerAlign: 'left',
    flex: 0.5,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      return `${params?.row?.employeeId}`;
    },
    sortComparator: sortAlphaNumeric,
  },
  {
    field: 'employeeFirstName',
    headerName: 'Employee Name',
    headerAlign: 'left',
    flex: 1,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      return `${params?.row?.employeeFirstName}`;
    },
  },
  {
    field: 'gender',
    headerName: 'Gender',
    headerAlign: 'left',
    flex: 0.8,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      return `${params?.row?.gender || '-'}`;
    },
  },
  {
    field: 'employeeAge',
    headerName: 'Age',
    headerAlign: 'left',
    flex: 0.5,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      return `${params?.row?.employeeAge || '-'}`;
    },
  },
  {
    field: 'medicalIssues',
    headerName: 'Health Problems/Recent Chronic illness',
    headerAlign: 'left',
    flex: 1.5,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      renderCellTooltip(params.row.medicalIssues || '-', 10);
    },
  },
  {
    field: 'employeePhone',
    headerName: 'Mobile No',
    headerAlign: 'left',
    flex: 1,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      renderCellTooltip(params.row.employeePhone || '-', 10);
    },
  },
  {
    field: 'employeeEmergencyContact',
    headerName: 'Emergancy Contact No',
    headerAlign: 'left',
    flex: 1,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      renderCellTooltip(params.row.employeeEmergencyContact || '-', 10);
    },
  },
  {
    field: 'employeeEmail',
    headerName: 'Email',
    headerAlign: 'left',
    flex: 1,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      renderCellTooltip(params.row.employeeEmail || '-', 12);
    },
  },
  {
    field: 'tshirtSize',
    headerName: 'Tshirt Size',
    headerAlign: 'left',
    flex: 1,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      return `${params?.row?.tshirtSize || '-'}`;
    },
  },
  {
    field: 'availableForOffsite',
    headerName: 'Offsite',
    headerAlign: 'left',
    flex: 0.75,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      // eslint-disable-next-line no-nested-ternary
      return params?.row?.submitted === 'Yes' ? (
        params?.row?.availableForOffsite === 'Yes' ? (
          <Tooltip title="Yes">
            <TaskAltIcon sx={{ color: 'green' }} />
          </Tooltip>
        ) : (
          <>
            <Tooltip title="No">
              <CancelOutlinedIcon sx={{ color: 'red' }} />
            </Tooltip>
          </>
        )
      ) : (
        <>-</>
      );
    },
  },
  {
    field: 'reasonForNotAttending',
    headerName: 'Reason',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return params?.row?.reasonForNotAttending || '-';
    },
  },
  {
    field: 'isNearPune',
    headerName: 'In or around Pune',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return params?.row?.isNearPune;
    },
  },
  {
    field: 'isTravelRequired',
    headerName: 'Travel',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return params?.row?.isTravelRequired;
    },
  },
  {
    field: 'employeeCurrentCity',
    headerName: 'Current City',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return params?.row?.employeeCurrentCity;
    },
  },
  {
    field: 'isAccommodationRequired',
    headerName: 'Accommodation',
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      return params?.row?.isAccommodationRequired;
    },
  },
  {
    field: 'submitted',
    headerName: 'Submitted',
    headerAlign: 'left',
    flex: 0.75,
    sortable: true,
    cellClassName: 'left-align-number-field',
    renderCell: (params: GridRenderCellParams) => {
      return params?.row?.submitted === 'Yes' ? (
        <Tooltip title="Yes">
          <TaskAltIcon sx={{ color: 'green' }} />
        </Tooltip>
      ) : (
        <>
          <Tooltip title="No">
            <CancelOutlinedIcon sx={{ color: 'red' }} />
          </Tooltip>
        </>
      );
    },
  },
  {
    field: 'submittedOn',
    type: 'string',
    headerName: 'Submitted On',
    flex: 1,
  },
  {
    field: 'updatedOn',
    type: 'string',
    headerName: 'Updated On',
    flex: 1,
  },
  {
    field: 'remarks',
    type: 'string',
    headerName: 'Remarks',
    flex: 2,
    hideable: true,
    filterable: false,
    editable: true,
    renderEditCell: (params: any) => {
      return <CustomMultiLineEdit {...params} />;
    },
    renderCell: (params: GridRenderCellParams) => {
      renderCellTooltip(params.row.remarks, 32);
    },
  },
];

const getInitialVisibilityModel = ({ showColumns }: any) => {
  const initialVisibilityModelObj: any = {};
  columns.forEach((col: any) => {
    initialVisibilityModelObj[col.field] = showColumns.includes(col.field);
  });

  return initialVisibilityModelObj;
};

const initialShowCols = [
  'employeeId',
  'employeeFirstName',
  'gender',
  'employeeAge',
  'availableForOffsite',
  'employeeEmail',
  'employeeEmergencyContact',
  'employeePhone',
  'medicalIssues',
  'remarks',
];
const employeeId = 'employeeUid';

export {
  dynamicColumns,
  tableRowTotalField,
  columns,
  initialShowCols,
  getInitialVisibilityModel,
  employeeId,
};
