export const columnLinked = [
  { value: 'name', label: 'Name', type: 'string' },
  { value: 'city', label: 'City', type: 'string' },
  { value: 'state', label: 'State', type: 'string' },
  { value: 'country', label: 'Country', type: 'string' },
  { value: 'companySpecialities', label: 'Company Specialities', type: 'string' },
  { value: 'designation', label: 'Designation', type: 'string' },
  { value: 'company_head_quarters', label: 'Company Headquarters', type: 'string' },
  { value: 'company_url', label: 'Company url', type: 'string' },
  { value: 'remarks', label: 'Remarks', type: 'string' },
  { value: 'company_size', label: 'Size', type: 'number' },
  { value: 'is_relevant', label: 'Relevant', type: 'boolean' },
];

export const operatorOptions = [
  { value: 'contains', label: 'Contains' },
  { value: 'equal', label: 'Equal' },
  { value: 'startswith', label: 'Startswith' },
  { value: 'endswith', label: 'Endswith' },
  { value: 'not_equal', label: 'Not Equal' },
  { value: 'not_contains', label: 'Not Contains' },
];

export const sizeOperatorOptions = [
  { value: 'greater_than', label: 'Greater than' },
  { value: 'less_than', label: 'Less than' },
  { value: 'greater_than_equal', label: 'Greater than equal' },
  { value: 'less_than_equal', label: 'Less than equal' },
  { value: 'equal', label: 'Equal' },
];

export const relevantOperatorOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const getColumnDataType = (columnName: string, columnDataTypes: any): string => {
  let returnType;
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < columnDataTypes.length; index++) {
    const element = columnDataTypes[index];
    if (element.value === columnName) {
      returnType = element.type;
    }
  }
  return returnType;
};

export const isBooleanColumn = (columnName: any, columnDataTypes: any): any => {
  const columnType = getColumnDataType(columnName.value, columnDataTypes);
  return columnType === 'boolean';
};

export const onColumnChange = (columnName: any, columnDataTypes: any) => {
  const columnType = getColumnDataType(columnName.value, columnDataTypes);
  if (columnType === 'boolean') {
    return relevantOperatorOptions;
  }
  if (columnType === 'number') {
    return sizeOperatorOptions;
  }
  return operatorOptions;
};
