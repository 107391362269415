/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Switch,
  Link as SimpleLink,
  Typography,
  TextField,
  Autocomplete,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AddCircleRounded } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import ProjectForm from './ProjectForm';
import MisTabs from '../../components/MisTabs/MisTabs';
import { GetProjectParams, ProjectDetail } from '../../api/Project/projectTypes';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import { GlobalContext } from '../../contexts/GlobalContext';
import ResourceClient, { PATHS as resourcePaths } from '../../api/Resource/resourceAPIs';
import { ApiOperations, UserGroupRoles } from '../../utils/utils';
import useResourceDetailStyles from './ResourceDetailStyles';
import useInterviewSideCArdStyles from '../Interview/InterviewsideCardStyles';
import ManagerFields from '../../components/ManagerFields/ManagerFields';
import InterviewClient from '../../api/Interview/interviewAPIs';
import Datatable from '../../components/Datatable/Datatable';
import {
  SearchProjectHistoryForm,
  getFirstDateOfSixMonthsBack,
  getLastDateOfCurrentMonth,
} from './forms/SearchProjectHistoryForm';
import {
  ResourceAllocationHistory,
  ResourceProjectAllocationHistoryApiParams,
} from '../../api/Resource/resourceTypes';
import { generateRows } from '../../components/Datatable/datatableUtils';
import {
  getColumns,
  initialSort,
  tableRowTotalField,
  resourceIdName,
} from './ResourceAllocationHistoryConfig';

interface ResourceDetailProps {
  readonly selectedResource: any;
  readonly handleClose: any;
  readonly handleSuccessfulSubmit: any;
  readonly employees: any;
  readonly updateFetch: any;
}

const ResourceDetail: React.FC<ResourceDetailProps> = ({
  selectedResource,
  handleClose,
  handleSuccessfulSubmit,
  employees,
  updateFetch,
}) => {
  const [resource, setResource] = useState({
    employeeName: '',
    employeeDesignation: '',
  });
  const [projectTabs, setProjectTabs] = useState([]);
  const styles = useResourceDetailStyles();
  const linkStyle = useInterviewSideCArdStyles();
  const [projects, setProjects] = useState<ProjectDetail[]>([]);
  const [availableProjects, setAvailableProjects] = useState<any[]>([]);
  const [disableAddBtn, setDisableAddBtn] = useState(false);
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [isLoadingProject, setIsLoadingProject] = useState(false);
  const [fetchManager, setFetchManager] = useState(false);
  const [primaryProject, setPrimaryProject] = useState<number | null>(null);
  const [currManager, setCurrManager] = useState<any>({
    manager: null,
    startDate: null,
  });
  const [newManager, setNewManager] = useState<any>({ ...currManager });
  const [interviewerRole, setInterviewerRole] = useState(false);
  const [isShowAllocationHistory, setisShowAllocationHistory] = useState(false);
  const [showAllocationHistory, setShowAllocationHistory] = useState<ResourceAllocationHistory[]>(
    [],
  );
  const [category, setCategory] = useState<{ label: string; value: string }>({
    label: '',
    value: '',
  });
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const { checkAccess, employeeUid, groupNames } = useContext(GlobalContext);
  const { id } = useParams();
  const containerRef = useRef<HTMLDivElement>(null);

  const getTableRows = (data: any) => {
    const formattedData = data.map((el: any, index: number) => ({ key: index, ...el }));
    const rows = generateRows(data, [], tableRowTotalField, resourceIdName);
    return rows;
  };
  const generateTabs = (projectDetails: any, _availableProjects: any[], _projects: any[]) => {
    return projectDetails.map((item: any, index: number) => {
      return {
        label: `P${index + 1}`,
        component: (
          <ProjectForm
            availableProjects={_availableProjects}
            projectDetail={item}
            projects={_projects}
            index={index + 1}
            primaryProject={primaryProject}
            employeeUid={selectedResource.employeeUid}
            handleSuccessfulSubmit={handleSuccessfulSubmit}
            setDisableAddBtn={setDisableAddBtn}
          />
        ),
      };
    });
  };

  const getManager = () => {
    setFetchManager(true);
    ResourceClient.getEmployeeManager(selectedResource.employeeUid)
      .then((res) => {
        setCurrManager({
          manager: {
            label: res.data?.managerName || '',
            value: res.data?.managerUid || '',
            uid: res.data?.uid || '',
          },
          startDate: res.data?.startDate || dayjs().format('YYYY-MM-DD'),
        });
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setFetchManager(false));
  };

  const fetchAllocationHistory = (providedStartDate?: string, providedEndDate?: string) => {
    setIsLoadingProject(true);
    const fetchAllocationHistoryParams: ResourceProjectAllocationHistoryApiParams = {
      employeeUid: selectedResource.employeeUid,
      startDate: providedStartDate || getFirstDateOfSixMonthsBack(),
      endDate: providedEndDate || getLastDateOfCurrentMonth(),
    };
    ResourceClient.getResourceAllocationHistory(fetchAllocationHistoryParams)
      .then((result) => {
        const rows = getTableRows(result);
        setShowAllocationHistory(rows);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoadingProject(false));
  };

  const handleToggleExpand = () => {
    setisShowAllocationHistory((prev) => !prev);
    if (!isShowAllocationHistory) {
      fetchAllocationHistory();
    }
  };

  const onSubmit = (values: any) => {
    fetchAllocationHistory(values.startDate, values.endDate);
  };

  const handleManagerChange = (name: string, value: any) => {
    setNewManager({
      ...newManager,
      [name]: value,
    });
  };

  const handleInterviewer = (updateInterviewerRoleParams: any) => {
    setIsLoading(true);
    InterviewClient.handleInterviewerRole(updateInterviewerRoleParams)
      .then((res) => {
        showSnackbar(res, 'success');
        updateFetch();
      })
      .catch((e) => {
        showSnackbar(e, 'error');
        setInterviewerRole((prevRole) => !prevRole);
      })
      .finally(() => setIsLoading(false));
  };

  const handleRemoveInterviewer = () => {
    const updateInterviewerRoleParams = {
      employeeUid: id,
      isActive: false,
    };
    handleInterviewer(updateInterviewerRoleParams);
    setInterviewerRole(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (interviewerRole && !event.target.checked) {
      handleRemoveInterviewer();
    } else {
      setInterviewerRole(event.target.checked);
    }
  };

  const handleAddProject = () => {
    const newProjectTabs: any = [...projectTabs];
    const newProjectDetail = {
      projectUid: null,
      isBilled: false,
      allocationPercentage: 0,
      billingRate: 0,
      startDate: dayjs().format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      isPrimary: false,
    };

    newProjectTabs.push({
      label: `P${projectTabs.length + 1}`,
      component: (
        <ProjectForm
          availableProjects={availableProjects}
          projectDetail={newProjectDetail}
          projects={projects}
          index={projectTabs.length + 1}
          primaryProject={primaryProject}
          employeeUid={selectedResource.employeeUid}
          handleSuccessfulSubmit={handleSuccessfulSubmit}
          setDisableAddBtn={setDisableAddBtn}
        />
      ),
    });
    setProjectTabs(newProjectTabs);
  };

  useEffect(() => {
    if (selectedResource) {
      getManager();
    }
  }, [selectedResource]);

  useEffect(() => {
    setIsLoading(true);
    setResource({
      employeeName: `${selectedResource?.employeeFirstName} ${selectedResource?.employeeLastName}`,
      employeeDesignation: selectedResource?.employeeDesignation,
    });

    const projectDetails = selectedResource?.projectDetails || [];
    const assignedProjects = projectDetails.map((el: any) => el.projectUid);
    const newAvailableProjects = projects.filter(
      (proj) => !assignedProjects.includes(proj.projectUid),
    );

    setAvailableProjects(newAvailableProjects);

    const primaryArr: number[] =
      selectedResource.projectDetails?.map(({ isPrimary }: any) => isPrimary) || [];
    setPrimaryProject(primaryArr?.indexOf(1) === -1 ? null : primaryArr.indexOf(1) + 1);
    setIsLoading(false);

    return () => {
      setDisableAddBtn(false);
      setActiveTab(null);
    };
  }, [selectedResource, projects]);

  useEffect(() => {
    if (selectedResource.projectDetails) {
      setProjectTabs(generateTabs(selectedResource.projectDetails, availableProjects, projects));
    } else setProjectTabs([]);
  }, [availableProjects, primaryProject, projects]);

  useEffect(() => {
    setIsLoadingProject(true);
    const getProjectParams: GetProjectParams = {
      project_id: '',
      is_active: true,
      show_total_billing: false,
    };
    ResourceClient.getProjectList(getProjectParams)
      .then((result) => {
        setProjects(result);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoadingProject(false));
  }, []);

  useEffect(() => {
    setNewManager(currManager);
  }, [currManager]);

  useEffect(() => {
    if (selectedResource.isInterviewer === 'Yes') {
      setInterviewerRole(true);
    } else {
      setInterviewerRole(false);
    }
    if (selectedResource?.category) {
      setCategory({ value: selectedResource.category, label: selectedResource.category });
    }
  }, [selectedResource]);

  useEffect(() => {
    if (interviewerRole) {
      const isAlreadyInterviewer = selectedResource.isInterviewer === 'Yes';
      if (!isAlreadyInterviewer) {
        const updateInterviewerRoleParams = {
          employeeUid: id,
          isActive: true,
        };
        handleInterviewer(updateInterviewerRoleParams);
      }
    }
  }, [interviewerRole]);

  useEffect(() => {
    setisShowAllocationHistory(false);
  }, [selectedResource]);

  const handleCategoryChange = (categoryValue: string, option: any) => {
    setCategoryLoading(true);
    InterviewClient.updateCategory([{ employeeUid: id, category: option }])
      .then((res) => {
        showSnackbar(res, 'success');
        setCategory({ value: option, label: option });
        updateFetch();
      })
      .catch((e) => {
        showSnackbar(e, 'error');
      })
      .finally(() => setCategoryLoading(false));
  };

  return (
    <Box className={styles.detailsWrapper} key={selectedResource.employeeUid}>
      <Box className={`${styles.topContainer} ${styles.card}`}>
        <strong>{resource.employeeName}</strong>
        <p>{resource?.employeeDesignation}</p>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {!isShowAllocationHistory && (
        <>
          <Box className={`${styles.bottomContainer} ${styles.card}`}>
            <Box className={styles.bottomContainerTitle}>
              {intl.formatMessage({
                id: I18nKey.RESOURCE_DETAILS_MANAGER,
              })}
            </Box>
            <Divider />
            <Box className={styles.managerFields}>
              <ManagerFields
                employees={employees}
                handleManagerChange={handleManagerChange}
                selectedResource={selectedResource}
                currManager={currManager}
                newManager={newManager}
                handleSuccessfulSubmit={handleSuccessfulSubmit}
                fetchManager={fetchManager}
              />
            </Box>
          </Box>
          {isLoadingProject ? (
            <Box className={styles.spinnerWrapper}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Box className={`${styles.bottomContainer} ${styles.card}`} ref={containerRef}>
              <Box className={styles.bottomContainerTitleWrapper}>
                <Box className={styles.bottomContainerTitle}>
                  {intl.formatMessage({
                    id: I18nKey.RESOURCE_DETAILS_PROJECT,
                  })}
                </Box>
                {(selectedResource.employeeUid !== employeeUid ||
                  groupNames.includes(UserGroupRoles.SUPERUSER) ||
                  groupNames.includes(UserGroupRoles.SYSTEM_ADMIN)) &&
                  checkAccess(resourcePaths.EMPLOYEE_PROJECT('id', 'id'), ApiOperations.POST) && (
                    <Button
                      disabled={
                        projectTabs.length === 5 || disableAddBtn || availableProjects.length <= 0
                      }
                      className={styles.addBtn}
                      onClick={handleAddProject}
                      size="small">
                      <AddCircleRounded />
                      {intl.formatMessage({
                        id: I18nKey.RESOURCE_DETAILS_ADD_PROJECT,
                      })}
                    </Button>
                  )}
              </Box>
              <Divider />
              <Box className={styles.formWrapper}>
                {isLoading && projectTabs.length === 0 ? (
                  <Box className={styles.spinnerWrapper}>
                    <CircularProgress color="primary" />
                  </Box>
                ) : (
                  <Box className={styles.resourceTabWrapper}>
                    <MisTabs tabs={projectTabs} selected={activeTab} />
                  </Box>
                )}
              </Box>
            </Box>
          )}
          <Box className={`${styles.bottomContainer} ${styles.card}`}>
            {categoryLoading ? (
              <Box className={styles.spinnerWrapperInterviewer}>
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Box className={styles.categoryContainer}>
                <Box className={styles.bottomContainerTitle}>Category</Box>
                <Box className={styles.bottomContainerTitle}>
                  <Autocomplete
                    id="category"
                    options={[
                      { value: 'L1', label: 'L1' },
                      { value: 'L2', label: 'L2' },
                      { value: 'L3', label: 'L3' },
                    ]}
                    renderOption={(props: any, option: any) => (
                      <MenuItem {...props} key={option.value}>
                        {option.label}
                      </MenuItem>
                    )}
                    onChange={(e: any, option: any) => {
                      handleCategoryChange(e, option.value);
                    }}
                    value={category}
                    disableClearable
                    renderInput={({ inputProps, ...rest }: any) => (
                      <TextField
                        {...rest}
                        size="small"
                        name="category"
                        label="Category"
                        fullWidth
                        inputProps={{ ...inputProps, style: { fontSize: 13 } }}
                      />
                    )}
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box className={`${styles.bottomContainer} ${styles.card}`}>
            {isLoading ? (
              <Box className={styles.spinnerWrapperInterviewer}>
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <Box className={styles.toggleButtonWrapper}>
                <Box className={styles.bottomContainerTitle}>Is Interviewer</Box>
                <Switch
                  checked={interviewerRole}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box>
            )}
          </Box>
        </>
      )}
      {isShowAllocationHistory && (
        <Box className={styles.expandedCard}>
          <Box className={styles.goBackWrapper}>
            <SimpleLink
              component="button"
              onClick={handleToggleExpand}
              className={styles.goBackLink}>
              {intl.formatMessage({
                id: I18nKey.GO_BACK_BUTTON,
              })}
            </SimpleLink>
            <SearchProjectHistoryForm handleSubmit={onSubmit} />
          </Box>

          {isLoadingProject ? (
            <Box className={styles.spinnerWrapper}>
              <CircularProgress color="primary" />
            </Box>
          ) : showAllocationHistory.length > 0 ? (
            <Box className={styles.historyTableWrapper}>
              <Datatable
                columns={getColumns()}
                rows={showAllocationHistory}
                pageSize={5}
                showFilterButton={false}
                initialSort={initialSort as any}
                tableHeight="60vh"
              />
            </Box>
          ) : (
            <Box className={styles.noProjectHistoryText}>
              <Typography>
                {intl.formatMessage({
                  id: I18nKey.NO_PROJECT_HISTORY_FOUND,
                })}
              </Typography>
            </Box>
          )}
        </Box>
      )}

      {!isShowAllocationHistory && (
        <SimpleLink
          component="button"
          onClick={handleToggleExpand}
          className={styles.showAlloCationHistoryBtn}>
          {intl.formatMessage({
            id: I18nKey.SHOW_PROJECT_ALLOCATION_BUTTON,
          })}
        </SimpleLink>
      )}
    </Box>
  );
};

export default ResourceDetail;
