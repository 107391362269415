const I18nKey: { [key: string]: any } = {
  NO_ROWS: 'noRows',
  LOGIN_ME: 'login.me',
  LOGIN_HEADER: 'login.header',
  LOGIN_SUB_HEADER: 'login.subHeader',
  DAILOG_EDIT: 'dailog.edit',
  DAILOG_FINALIZE: 'dailog.finalize',
  DAILOG_CANCEL: 'dailog.cancel',
  DAILOG_SAVE_MSG: 'dailog.saveMsg',
  DAILOG_CANCEL_MSG: 'dailog.cancelMsg',
  DAILOG_FINALIZE_MSG: 'dailog.finalizeMsg',
  NOT_FOUND_TITLE: '404.title',
  NOT_FOUND_SUBTITLE: '404.subTitle',
  NOT_FOUND_MESSAGE: '404.message',
  NOT_FOUND_BTN_TITLE: '404.btnTitle',
  NOT_FOUND_ACTION_MSG: '404.actionMsg',
  SOMETHING_WRONG_MSG: '505.message',
  ORG_TREE_CONTACT: 'orgTree.contact',
  ORG_TREE_LOCATION: 'orgTree.location',
  ORG_TREE_DEPARTMENT: 'orgTree.department',
  ORG_TREE_PROJECT: 'orgTree.project',
  ORG_TREE_DIRECT: 'orgTree.direct',
  ORG_TREE_REPORTEES: 'orgTree.reportees',
  ORG_VIEW: 'orgTree.orgview',
  INVOICE_DETAIL_TITLE: 'invoiceManagement.details.title',
  INVOICE_DETAIL_SUBTITLE: 'invoiceManagement.details.subTitle',
  INVOICE_DETAIL_PROJECT: 'invoiceManagement.details.project',
  INVOICE_DETAIL_INVOICE_NUMBER: 'invoiceManagement.details.invoiceNumber',
  INVOICE_DETAIL_CUSTOMER: 'invoiceManagement.details.customer',
  INVOICE_DETAIL_RESOURCES: 'invoiceManagement.details.resources',
  INVOICE_DETAIL_TYPE: 'invoiceManagement.details.resources',
  INVOICE_DETAIL_START_DATE: 'invoiceManagement.details.startDate',
  INVOICE_DETAIL_END_DATE: 'invoiceManagement.details.endDate',
  INVOICE_DETAIL_INVOICE_STATUS: 'invoiceManagement.details.invoiceStatus',
  INVOICE_DETAIL_TOTAL_REVENUE: 'invoiceManagement.details.totalRevenue',
  INVOICE_DETAIL_GENERATE_INVOICE: 'invoiceManagement.details.generateInvoice',
  INVOICE_DETAIL_EMPLOYEE_DETAIL: 'invoiceManagement.details.employeeDetail',
  INVOICE_DETAIL_OTHER_DETAIL: 'invoiceManagement.details.otherDetail',
  INVOICE_DETAIL_SHARE_CONFIRM_MSG: 'invoiceManagement.details.shareConfirmMsg',
  OTHER_DETAILS_DETAIL: 'invoiceManagement.otherDetails.detail',
  OTHER_DETAILS_AMOUNT: 'invoiceManagement.otherDetails.amount',
  OTHER_DETAILS_SUBMIT: 'invoiceManagement.otherDetails.submit',
  OTHER_DETAILS_UPDATE_ERR_MSG: 'invoiceManagement.otherDetails.updateErrorMsg',
  OTHER_DETAILS_INVALID_OP: 'invoiceManagement.otherDetails.invalidOp',
  REVENUE_DETAIL_TITLE: 'revenueManagement.details.title',
  REVENUE_DETAIL_SUBTITLE: 'revenueManagement.details.subTitle',
  REVENUE_DETAIL_PROJECT: 'revenueManagement.details.project',
  REVENUE_DETAIL_CUSTOMER: 'revenueManagement.details.customer',
  REVENUE_DETAIL_TOTAL_REVENUE: 'revenueManagement.details.totalRevenue',
  REVENUE_DETAIL_PAST_TOTAL_REVENUE: 'revenueManagement.details.lastTotalRevenue',
  REVENUE_DETAIL_RESOURCES: 'revenueManagement.details.resources',
  REVENUE_DETAIL_START_DATE: 'revenueManagement.details.startDate',
  REVENUE_DETAIL_END_DATE: 'revenueManagement.details.endDate',
  REVENUE_DETAIL_RESOURCE: 'revenueManagement.details.resource',
  REVENUE_DETAIL_DESIGNATION: 'revenueManagement.details.designation',
  REVENUE_DETAIL_PROJECTS: 'revenueManagement.details.projects',
  PROJECT_DETAIL_TITLE: 'projectManagement.details.title',
  PROJECT_DETAIL_SUBTITLE: 'projectManagement.details.subTitle',
  PROJECT_DETAIL_PROJECT_NAME: 'projectManagement.details.projectName',
  PROJECT_DETAIL_CUSTOMER_NAME: 'projectManagement.details.customerName',
  PROJECT_DETAIL_RESOURCES: 'projectManagement.details.resources',
  PROJECT_DETAIL_TYPE: 'projectManagement.details.type',
  PROJECT_DETAIL_REVENUE: 'projectManagement.details.revenue',
  PROJECT_DETAIL_PROJECT_MANAGER: 'projectManagement.details.projectManager',
  PROJECT_DETAIL_START_DATE: 'projectManagement.details.startDate',
  PROJECT_DETAIL_END_DATE: 'projectManagement.details.endDate',
  PORJECT_DETAIL_REMOVE_PROJECT: 'projectManagement.removeProject',
  PORJECT_DETAIL_CLOSE_PROJECT: 'projectManagement.closeProject',
  CONTRACT_ALL_BTN_ALL: 'contractManagement.all.btn.all',
  CONTRACT_ALL_BTN_NO_SOW_F0: 'contractManagement.all.btn.no_sow_f0',
  CONTRACT_ALL_BTN_SOW_EXPIRED: 'contractManagement.all.btn.sow_expired',
  CONTRACT_ALL_BTN_SOW_EXPIRING: 'contractManagement.all.btn.sow_expiring',
  CONTRACT_ALL_BTN_MSA_EXPIRED: 'contractManagement.all.btn.msa_expired',
  CONTRACT_ALL_BTN_MSA_EXPIRING: 'contractManagement.all.btn.msa_expiring',
  CONTRACT_DETAIL_TITLE: 'contractManagement.details.title',
  CONTRACT_TABLE_TITLE: 'contractTable.title',
  CONTRACT_DETAIL_SUBTITLE: 'contractManagement.details.subTitle',
  CONTRACT_DETAIL_INFO: 'contractManagement.details.info',
  CONTRACT_DETAIL_MSA_END_DATE: 'contractManagement.details.msaEndDate',
  CONTRACT_DETAIL_SOW_END_DATE: 'contractManagement.details.sowEndDate',
  CONTRACT_DETAIL_POC: 'contractManagement.details.poc',
  CONTRACT_DETAIL_PO: 'contractManagement.details.po',
  CONTRACT_DETAIL_EMAIL: 'contractManagement.details.email',
  CONTRACT_DETAIL_NAME: 'contractManagement.details.name',
  CONTRACT_DETAIL_ADDRESS: 'contractManagement.details.address',
  CONTRACT_DETAIL_SOW_VALUE: 'contractManagement.details.sowValue',
  CONTRACT_DETAIL_STAGE: 'contractManagement.details.stage',
  CONTRACT_DETAIL_OPP_POC: 'contractManagement.details.opportunityPoc',
  CONTRACT_DETAIL_CUSTOMER_INFO: 'contractManagement.details.customerInformation',
  CONTRACT_DETAIL_CUSTOMER_POC: 'contractManagement.details.customerPoc',
  RESOURCE_TITLE: 'resourceManagement.title',
  RESOURCE_UPDATE_TITLE: 'resourceManagement.updateTitle',
  RESOURCE_DETAILS_CLOSE: 'resourceManagement.details.close',
  RESOURCE_DETAILS_REMARK: 'resourceManagement.details.remark',
  RESOURCE_DETAILS_ADD_PROJECT: 'resourceManagement.details.addProject',
  RESOURCE_DETAILS_PROJECT: 'resourceManagement.details.project',
  RESOURCE_DETAILS_MANAGER: 'resourceManagement.details.manager',
  RESOURCE_DETAILS_UNASSIGN: 'resourceManagement.unassign',
  RESOURCE_DETAILS_UPDATE: 'resourceManagement.update',
  RESOURCE_LIST_BY_SKILL: 'resourceManagement.viewBySkills',
  RESOURCE_LIST_BY_PROJECT: 'resourceManagement.viewByProjects',
  CUSTOMER_RELATION_TITLE: 'customerRelationManagement.details.title',
  CUSTOMER_RELATION_ALT_TITLE: 'customerRelationManagement.details.altTitle',
  CUSTOMER_RELATION_DETAIL_SUBTITLE: 'customerRelationManagement.details.subTitle',
  CUSTOMER_RELATION_OPPORTUNITY: 'customerRelationManagement.details.addOpportunity',
  CUSTOMER_RELATION_ACCOUNT: 'customerRelationManagement.details.addAccount',
  CUSTOMER_RELATION_CUSTOMER: 'customerRelationManagement.details.addCustomer',
  CUSTOMER_RELATION_CONTACT: 'customerRelationManagement.details.addContact',
  CUSTOMER_RELATION_ADD_CUSTOMER_BTN: 'customerRelationManagement.list.add.addCustomerPOC',
  CUSTOMER_RELATION_OPPORTUNITY_STAGE: 'customerRelationManagement.details.opportunityStage',
  CUSTOMER_RELATION_REVENUE_FORECAST: 'customerRelationManagement.details.revenueForecast',
  CUSTOMER_RELATION_STAGE_WARNING: 'customerRelationManagement.details.stageMovingMsg',
  CUSTOMER_RELATION_REMOVE_OPPORTUNITY: 'customerRelationManagement.list.removeOpportunity',
  CUSTOMER_RELATION_CLOSE_OPPORTUNITY: 'customerRelationManagement.list.closeOpportunity',
  ACCOUNT_TITLE: 'account.title',
  CUSTOMER_TITLE: 'customer.title',
  CONTACT_TITLE: 'contact.title',
  PROFILE_TITLE: 'profile.title',
  PROFILE_INSURANCEE_DETAILS: 'profile.insuranceeDetails',
  PROFILE_NOMINEE_DETAILS: 'profile.nomineeDetails',
  PROFILE_TO_DO_LIST: 'profile.toDoList',
  PROFILE_EDIT_TASK: 'profile.editTodos',
  PROFILE_DELETE_TASK: 'profile.deleteTodos',
  PROFILE_REMOVE_TODO_TASK: 'profile.removeTodosTask',
  PROFILE_MY_SKILLS: 'profile.mySkills',
  PROFILE_MY_DOCUMENTS: 'profile.myDocuments',
  PROFILE_MY_FAMILY: 'profile.myFamily',
  PROFILE_VIEW_ALL: 'profile.viewAll',
  PROFILE_REMOVE_RELATION: 'profile.removeRelation',
  PROFILE_OFFSITE: 'offsite.title',
  BUTTON_LABEL_SAVE: 'buttonLabels.save',
  BUTTON_LABEL_REMOVE: 'buttonLabels.remove',
  BUTTON_LABEL_UNASSIGN: 'buttonLabels.unassign',
  BUTTON_LABEL_CANCEL: 'buttonLabels.cancel',
  BUTTON_LABEL_FINALIZE: 'buttonLabels.finalize',
  BUTTON_LABEL_CLOSE: 'buttonLabels.close',
  BUTTON_LABEL_UPDATE: 'buttonLabels.update',
  BUTTON_LABEL_ADD: 'buttonLabels.add',
  BUTTON_LABEL_SUBMIT: 'buttonLabels.submit',
  SUBMIT_DISABLED_TOOLTIP: 'buttonLabels.saveDisabledToolTip',
  BUTTON_LABEL_DOWNLOAD_SELECTED: 'buttonLabels.downloadSelected',
  BUTTON_LABEL_COMBINE_INVOICES: 'buttonLabels.combineInvoices',
  COMBINE_INVOICES_MODAL_HEADING: 'buttonLabels.combineInvoicesModalHeading',
  TOAST_MESSAGE_ADD: 'toastMessages.add',
  TOAST_MESSAGE_UPDATE: 'toastMessages.update',
  TOAST_MESSAGE_DELETE: 'toastMessages.delete',
  TOAST_MESSAGE_ERROR: 'toastMessages.error',
  TOAST_MESSAGE_NOT_FOUND: 'toastMessages.notFound',
  ONBOARDING_TITLE: 'onBoardingManagement.title',
  ONBOARDING_EMPLOYEE: 'onBoardingManagement.onboarding',
  ONBOARDING_EMPLOYEE_DETAILS: 'onBoardingManagement.employeeDetails',
  ONBOARDING_NEWJOINEE_DETAILS: 'onBoardingManagement.newJoineeDetails',
  ONBOARDING_ADD_EMPLOYEE: 'onBoardingManagement.addEmployee',
  APPRAISAL_TITLE: 'appraisalManagement.title',
  APPRAISAL_SUBTITILE: 'appraisalManagement.subTitle',
  APPRAISAL_FYTITLE: 'appraisalManagement.titleFY',
  APPRAISAL_KRA_TEMPLATE: 'appraisalManagement.kraTemplate',
  APPRAISAL_ADD_KRA_TEMPLATE: 'appraisalManagement.addKraTemplate',
  APPRAISAL_START_APPRAISAL: 'appraisalManagement.startAppraisal',
  APPRAISAL_SELF_APPRAISAL: 'appraisalManagement.selfAppraisal',
  APPRAISAL_RESOURCE_DESIGNATION: 'appraisalManagement.resourceDetails.designation',
  APPRAISAL_RESOURCE_MANAGER: 'appraisalManagement.resourceDetails.manager',
  APPRAISAL_MANAGER_DURATION: 'appraisalManagement.resourceDetails.duration',
  APPRAISAL_START_DATE: 'appraisalManagement.resourceDetails.startDate',
  APPRAISAL_END_DATE: 'appraisalManagement.resourceDetails.endDate',
  APPRAISAL_SUBMIT_CONFIRMATION: 'appraisalManagement.list.submitAppraisal',
  APPRAISAL_SUBMIT_EMPLOYEE_CONFIRMATION: 'appraisalManagement.list.submitEmployeeAppraisal',
  APPRAISAL_CLOSE_CONFIRMATION: 'appraisalManagement.list.closeAppraisal',
  APPRAISAL_DELETE_CONFIRMATION: 'appraisalManagement.list.deleteAppraisal',
  APPRAISAL_LOCK_GOALS: 'appraisalManagement.list.lockGoals',
  APPRAISAL_EMPLOYEE_AVGRATING: 'appraisalManagement.resourceDetails.empAvgRating',
  APPRAISAL_MANAGER_AVGRATING: 'appraisalManagement.resourceDetails.managerAvgRating',
  ONBOARDING_DELETE_EMPLOYEE: 'onBoardingManagement.deleteEmployee',
  ONBOARDING_DELETE_EMPLOYEE_DETAILS: 'onBoardingManagement.deleteEmployeeDetails',
  ONBOARDING_DELETE_NEWJOINEE: 'onBoardingManagement.deleteNewJoinee',
  ONBOARDING_DELETE_NEW_JOINEE_MSG: 'onBoardingManagement.deleteNewJoineeMessage',
  GENERATE_DOCUMENT_TITLE: 'generateDocument.title',
  OFFER_LETTER: 'generateDocument.offerLetter',
  INTERNSHIP_OFFER_LETTER: 'generateDocument.intershipOfferLetter',
  CANDIDATE_TITLE: 'generateDocument.candidates',
  EMPLOYEE_TITLE: 'generateDocument.employees',
  NEW_OFFER_LETTER: 'generateDocument.newOfferLetter',
  REMOVE_CANDIDATE: 'generateDocument.removeCandidate',
  SHARE_OFFERLETTER: 'generateDocument.shareOfferLetter',
  COMPENSATION_LETTER: 'generateDocument.compensationLetter',
  COMPENSATION_LETTER_SUBJECT: 'generateDocument.compensationLetterSubject',
  COMPENSATION_LETTER_TITLE: 'generateDocument.compensationLetterTitle',
  SHARE_COMPENSATION_LETTER: 'generateDocument.shareCompensationLetter',
  LETTER: 'generateDocument.letter',
  SUBJECT: 'generateDocument.subject',
  INTERNSHIP_SUBJECT: 'generateDocument.internShipSubject',
  INTERNSHIP_MESSAGE_BODY: 'generateDocument.internShipMessageBody',
  MESSAGEBODY: 'generateDocument.messageBody',
  COMPENSATION_MSG_BODY: 'generateDocument.messageBodyCompensation',
  AUDITLOG_TITLE: 'auditLog.title',
  INTERVIEW_MANAGEMENT_TITLE: 'interviewManagement.title',
  INTERVIEW_VIEW_INTERVIEWERS: 'interviewManagement.viewInterviewers',
  INTERVIEW_SUBMIT_CONFIRMATION: 'interviewManagement.submitInterview',
  L1_INTERVIEWER: 'interviewManagement.l1Interview',
  ADD_ATTRIBUTE: 'interviewManagement.attribute',
  ATTRIBUTE_MESSAGE: 'interviewManagement.attributeMsg',
  APPLICANT_MESSAGE: 'interviewManagement.applicantMsg',
  OFFERED_CANDIDATE: 'interviewManagement.offeredCandidates',
  NEW_INTERNSHIP_LETTER: 'generateDocument.newInternshipLetter',
  TICKET_MANAGEMENT_TITLE: 'ticketManagement.title',
  DASHBOARD: 'ticketManagement.dashBoard',
  VIEW_CATEGORY: 'ticketManagement.viewCategory',
  CATEGORY: 'ticketManagement.category',
  ADD_CATEGORY: 'ticketManagement.addCategory',
  EDIT_CATEGORY: 'ticketManagement.editCategory',
  TICKET_TITLE: 'ticketManagement.ticketTitle',
  PRIORITY: 'ticketManagement.priority',
  RAISEDBY: 'ticketManagement.raisdeBy',
  CREATE_TICKET: 'ticketManagement.addTicket',
  EDIT_TICKET: 'ticketManagement.editTicket',
  SAVE: 'ticketManagement.save',
  CREATE: 'ticketManagement.create',
  ATTACHMENT: 'ticketManagement.attachment',
  DELETE: 'ticketManagement.delete',
  TICKETS_MIS_DIALOG_TITLE: 'ticketManagement.ticketsDialog.title',
  TICKETS_MIS_DIALOG_MESSAGE: 'ticketManagement.ticketsDialog.message',
  TICKETS_MIS_DIALOG_DELETE: 'ticketManagement.ticketsDialog.delete',
  TICKET_MOVEMENT: 'ticketManagement.movement',
  TICKET_BOARD_TITLE_RESOLVED: 'ticketManagement.ticketBoard.resolved',
  TICKET_BOARD_TITLE_IN_PROGRESS: 'ticketManagement.ticketBoard.inProgress',
  TICKET_BOARD_TITLE_BLOCKED: 'ticketManagement.ticketBoard.blocked',
  TICKET_BOARD_TITLE_OPEN: 'ticketManagement.ticketBoard.open',
  TICKETS_STATUS_TIME_TIME_TAKEN: 'ticketManagement.status.time',
  TICKETS_STATUS_TIME_RESOLVED: 'ticketManagement.status.resolved',
  TICKETS_STATUS_TIME_ACTIVE: 'ticketManagement.status.active',
  TICKETS_FILTER_SEARCH: 'ticketManagement.ticketsFilter.search',
  TICKETS_FILTER_PRIORITY: 'ticketManagement.ticketsFilter.priority',
  TICKETS_FILTER_URGENT: 'ticketManagement.ticketsFilter.urgent',
  TICKETS_FILTER_LOW: 'ticketManagement.ticketsFilter.low',
  TICKETS_FILTER_NORMAL: 'ticketManagement.ticketsFilter.normal',
  TICKETS_FILTER_HIGH: 'ticketManagement.ticketsFilter.high',
  TICKETS_FILTER_CATEGORY: 'ticketManagement.ticketsFilter.category',
  TICKETS_FILTER_NONE: 'ticketManagement.ticketsFilter.none',
  TICKETS_FILTER_RAISED_BY: 'ticketManagement.ticketsFilter.raisedBy',
  TICKETS_FILTER_ASSIGNED_TO: 'ticketManagement.ticketsFilter.assignedTo',
  TICKETS_FILTER_START_DATE: 'ticketManagement.ticketsFilter.startDate',
  TICKETS_FILTER_END_DATE: 'ticketManagement.ticketsFilter.endDate',
  TICKETS_FILTER_CLEAR: 'ticketManagement.ticketsFilter.clear',
  TICKETS_FILTER_CLOSE: 'ticketManagement.ticketsFilter.close',
  LINKED_IN_TITLE: 'linkedInContacts.title',
  LINKED_IN_FILTER: 'linkedInContacts.filters',
  LINKED_IN_ADDFILTER: 'linkedInContacts.addfilter',
  LINKED_IN_CLEAR: 'linkedInContacts.clear',
  LINKED_IN_SEARCH: 'linkedInContacts.search',
  LINKED_IN_ADD_RELEVANT: 'linkedInContacts.addRelevant',
  LINKED_IN_DELETE_RELEVANT: 'linkedInContacts.deleteRelevant',
  INVOICE_MAIL_DETAIL: 'projectManagement.invoiceMailDetails',
  EMAIL_AUTHORISED: 'onBoardingManagement.emailAuthorised',
  TOKEN_VALID: 'onBoardingManagement.tokenValid',
  COMPANY_NAME: 'onBoardingManagement.comapanyName',
  SHOW_PROJECT_ALLOCATION_BUTTON: 'Show Project Allocation History',
  NO_PROJECT_HISTORY_FOUND: 'No Project History Found',
  GO_BACK_BUTTON: '<< Back',
  SUBMIT_MSG: 'onBoardingManagement.submitMessage',
  NEW_CANDIDATE: 'onBoardingManagement.newCandidate',
  LINK_INFO: 'onBoardingManagement.linkInfo',
  HR_MSG: 'onBoardingManagement.hrMsg',
  HARDWARE_TITLE: 'hardware.title',
  HARDWARE_QUICK_LINK_TITLE: 'hardware.quicklinksTitle',
  ADD_ASSET_TITLE: 'hardware.addAssets',
  EMP_DEPENDENTS: 'family.dependents',
  // Home Dashboard
  POLICY: 'policy.title',
  ADD_POLICY: 'policy.addPolicy',
  SEARCH_TITLE: 'searchEmployee.title',
  HOME: 'searchEmployee.home',
  HOLIDAY: 'quickLink.holiday',
  HOLIDAYLIST: 'quickLink.holidayList',
  LEAVE_TITLE: 'leaveManagement.title',
  TIMESHEET_TITLE: 'timesheet.title',
  TIMESHEET_SUBMIT_CONFIRMATION: 'timesheet.submit',
  EMPLOYEE_BENIFIT: 'quickLink.employeeBenefits',
  EMAIL_ALIASES: 'quickLink.emailAliases',
  CLAIM_DEVICE: 'quickLink.claimDevice',
  FAQ: 'quickLink.faq',
  MODULE: 'workSpace.modules',
  OVERVIEW: 'workSpace.overview',
  VACANCIES_TITLE: 'vacancies.title',
  ADD_VACANCIES_TITLE: 'vacancies.addVacancyTitle',
  EDIT_VACANCIES_TITLE: 'vacancies.editVacancyTitle',
  DELETE_VACANCIES_TEXT: 'vacancies.deleteVacancyText',
  FAMILY_SPOUSE_INFO: 'family.spouseInfo',
  FAMILY_PARENTS_INFO: 'family.parentsInfo',
  FAMILY_CHILD_INFO: 'family.childrensInfo',
  FAMILY_ADD_DEPENDENTS: 'family.addDependents',
  FAMILY_SKIP_SPOUSE: 'family.skipSpouse',
  OFFSITE_TITLE: 'offsite.heading',
};

export default I18nKey;
