import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useCombineInvoiceStyles = makeStyles((theme: Theme) => {
  return {
    combineFormWrapper: {
      marginTop: '-30px',
    },
  };
});

export default useCombineInvoiceStyles;
