import { GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Tooltip from '@mui/material/Tooltip';
import { replaceEmpty } from '../../utils/utils';

const initialSort = { field: 'invoiceNumber', sort: 'asc' };

const dynamicColumns: any = [];

const tableRowTotalField: any = [];

export enum InvoiceStatus {
  FINALISED = 'finalised',
  GENERATED = 'generated',
  SHARED = 'shared',
}

export const getInvoiceStatusIcons = (status: string) => {
  switch (status) {
    case InvoiceStatus.FINALISED:
      return (
        <Tooltip title="Finalized">
          <PauseCircleOutlineIcon sx={{ color: 'orange' }} />
        </Tooltip>
      );
    case InvoiceStatus.GENERATED:
      return (
        <Tooltip title="Generated">
          <HighlightOffIcon sx={{ color: 'red' }} />
        </Tooltip>
      );
    case InvoiceStatus.SHARED:
      return (
        <Tooltip title="Shared">
          <TaskAltIcon sx={{ color: 'green' }} />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="Not Finalized">
          <AccessTimeIcon sx={{ color: 'grey' }} />
        </Tooltip>
      );
  }
};

const getColumns = (selectedMonth: string) => {
  return [
    {
      field: 'projectName',
      type: 'string',
      headerName: 'Project Name',
      flex: 2,
      sortable: true,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            to={`/invoice/${params.row.projectUid}?selected_month=${selectedMonth}&invoice_number=${
              params.row.invoiceNumber || 0
            }&upload_type=${params.row.invoiceUploadType}`}>
            {params.row.projectName}
          </Link>
        );
      },
    },
    {
      field: 'invoiceNumber',
      type: 'number',
      headerAlign: 'left',
      cellClassName: 'left-align-number-field',
      headerName: 'Invoice Number',
      flex: 1,
      sortable: true,
      hideable: false,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'invoiceUploadType',
      type: 'string',
      headerName: 'Invoice Upload Type',
      flex: 1,
      sortable: true,
      hideable: false,
      filterable: true,
    },
    {
      field: 'invoiceAmount',
      type: 'number',
      headerAlign: 'right',
      cellClassName: 'right-align-number-field',
      headerName: 'Total Revenue ($)',
      flex: 1,
      sortable: false,
      hideable: false,
      filterable: false,
      computable: true,
    },
    {
      field: 'invoiceStatus',
      type: 'string',
      headerName: 'Invoice Status',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      sortable: true,
      hideable: false,
      filterable: false,
      renderCell: ({ value }: GridRenderCellParams) => getInvoiceStatusIcons(value),
    },
  ];
};

const invoiceIdName = 'key';

export { getColumns, initialSort, dynamicColumns, tableRowTotalField, invoiceIdName };
