import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import useCombineInvoiceStyles from './ComineInvoiceFormStyles';

interface CombineInvoiceFormProps {
  readonly initialField: any;
  readonly setIsProjectFormValid: any;
  readonly formRef: any;
  readonly projectList: any;
}
const CombineInvoiceForm: React.FC<CombineInvoiceFormProps> = ({
  initialField,
  setIsProjectFormValid,
  formRef,
  projectList,
}: any) => {
  const styles = useCombineInvoiceStyles();

  const handleChange = (values: any): any => {
    setIsProjectFormValid(!values.project);
  };

  const formValidationSchema = Yup.object().shape({
    project: Yup.object()
      .shape({ label: Yup.string(), value: Yup.string() })
      .typeError('Project is required'),
  });

  return (
    <Formik
      initialValues={initialField}
      onSubmit={() => {}}
      validationSchema={formValidationSchema}
      innerRef={formRef}
      enableReinitialize>
      {(formik) => {
        const { values, setFieldValue } = formik;
        return (
          <Form className={styles.combineFormWrapper} onChange={handleChange(values)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SimpleAutocompleteField
                  label="Project"
                  name="project"
                  size="small"
                  setFieldValue={setFieldValue}
                  options={projectList}
                  values={values}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CombineInvoiceForm;
