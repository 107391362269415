import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Modal, Switch, TextField, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { GridRowParams, GridSelectionModel } from '@mui/x-data-grid';
import JSZip from 'jszip';
import AddIcon from '@mui/icons-material/Add';
import queryString from 'query-string';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import I18nKey from '../../translations/I18nKey';
import Datatable from '../../components/Datatable/Datatable';
import { generateRows, generateActionColumn } from '../../components/Datatable/datatableUtils';
import {
  initialSort,
  dynamicColumns,
  tableRowTotalField,
  getColumns,
  invoiceIdName,
  InvoiceStatus,
} from './invoiceTableConfig';
import InvoiceClient, { PATHS as invoicePaths } from '../../api/Invoice/invoiceAPIs';
import { GetInvoiceParams, InvoiceList, ShareInvoiceParams } from '../../api/Invoice/invoiceTypes';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import useAllInvoicesStyles from './AllInvoicesStyles';
import { getConvertedCurrency } from '../../utils/exchangeRates';
import MisDialog from '../../components/MisDialog/MisDialog';
import { ApiOperations } from '../../utils/utils';
import { GlobalContext } from '../../contexts/GlobalContext';
import SidePanel from '../../components/SidePanel/SidePanel';
import UploadInvoiceForm from './UploadInvoiceForm';
import ModalPopup from '../../components/ModalPopup/ModalPopup';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import CombineInvoiceForm from './CombineInvoiceForm';

const AllInvoices: React.FC = () => {
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCombineInvoicesModal, setShowCombineInvoicesModal] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectionModel, setSelectionModel] = React.useState<any>([]);
  const [projectList, setProjectList] = React.useState<any>([]);
  const [project, setProject] = React.useState<any>({});
  const [isCombineButton, setIsCombineButton] = useState<boolean>(false);
  const [initialFields, setInitialFields] = React.useState<any>({ project: null });
  const [isProjectFormValid, setIsProjecFormValid] = useState(true);
  const formRef = useRef<any>();

  const [currentInvoice, setCurrentInvoice] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  // This state is used to refetch the invoice list data.
  const [fetch, setFetch] = useState(0);
  const [confirmShareInfo, setConfirmShareInfo] = useState<any>();
  const allInvoicesStyles = useAllInvoicesStyles();
  const { showSnackbar } = useDisplaySnackbar();
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const { checkAccess } = useContext(GlobalContext);

  const queryParams = queryString.parse(location?.search);

  const [selectedMonth, setSelectedMonth] = useState<any>(
    // eslint-disable-next-line no-nested-ternary
    queryParams.selected_month
      ? dayjs(queryParams.selected_month as string)
      : dayjs().date() <= 5
      ? dayjs().subtract(1, 'month')
      : dayjs(),
  );
  const handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMonth(dayjs(event.target?.value));
    navigate({
      search: createSearchParams({
        selected_month: event.target?.value,
      }).toString(),
    });
  };

  const downloadInvoice = (url: string, fileName: string) => {
    const downloadInvoiceParams = {
      preSignedUrl: url,
    };
    return InvoiceClient.downloadInvoice(downloadInvoiceParams)
      .then((res) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        link.download = fileName;
        link.click();
      })
      .catch((e) => showSnackbar(e, 'error'));
  };

  const onGenerateInvoice = (params: any) => {
    setIsLoading(true);
    InvoiceClient.generateInvoice(
      params.projectUid,
      selectedMonth.endOf('month').format('YYYY-MM-DD'),
      {},
    )
      .then((downloadResult) => {
        downloadInvoice(downloadResult.data.url, downloadResult.data.fileName);
        setFetch((num) => num + 1);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const onDownloadInvoice = (params: any) => {
    setIsLoading(true);
    downloadInvoice(params.preSignedUrl, params.fileName);
    setIsLoading(false);
  };
  const handleCombineInvoices = () => {
    setShowCombineInvoicesModal(true);
  };

  const handleDownloadSelected = async () => {
    const selectedRowsData = tableRows.filter((row: any) => selectionModel.includes(row.id));
    const zip = new JSZip();
    setIsLoading(true);
    const fetchPromises = selectedRowsData.map((params: any) =>
      InvoiceClient.downloadInvoice({ preSignedUrl: params.preSignedUrl }),
    );
    Promise.allSettled(fetchPromises)
      .then(async (fetchResults) => {
        fetchResults.forEach(async (result: PromiseSettledResult<any>, index: number) => {
          if (result.status === 'fulfilled') {
            const response = result.value;
            const file = await response.data;
            zip.file(selectedRowsData[index]?.fileName, file, { binary: true });
          }
        });
      })
      .then(async () => {
        const zipFile = await zip.generateAsync({ type: 'blob' });
        const zipUrl = URL.createObjectURL(zipFile);
        const link = document.createElement('a');
        link.href = zipUrl;
        link.download = `${dayjs().format('YYYY_MM_DD')}_invoices.zip`;
        link.click();
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const onSendInvoice = (params: any) => {
    setIsLoading(true);
    InvoiceClient.getEmailRecipients(params.invoiceUid)
      .then(({ toAddr, ccAddr }) => {
        const toStr = toAddr.length ? `${toAddr.join(', ')} ` : '';
        const ccStr = ccAddr.length ? `${ccAddr.join(', ')}` : '';
        setConfirmShareInfo({ toStr, ccStr });
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => {
        setIsLoading(false);
        setOpenDialog(true);
        setCurrentInvoice(params);
      });
  };

  const onSendInvoiceConfirm = () => {
    setIsLoading(true);
    setOpenDialog(false);
    const shareInvoiceParams: ShareInvoiceParams = {
      invoiceUid: currentInvoice.invoiceUid,
    };

    InvoiceClient.shareInvoice(shareInvoiceParams)
      .then(() => setFetch((num) => num + 1))
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    setOpenDialog(false);
    setCurrentInvoice(null);
  };

  const onSuccessfulInvoiceUpload = () => {
    setIsOpen(false);
    setFetch((num) => num + 1);
  };

  const rowActions = [
    {
      label: 'Generate',
      logo: <AutorenewIcon />,
      callback: onGenerateInvoice,
      path: invoicePaths.GENERATE_INVOICE('id', dayjs().format('YYYY-MM-DD')),
      operation: ApiOperations.POST,
    },
    {
      label: 'Download',
      logo: <DownloadIcon />,
      callback: onDownloadInvoice,
      path: invoicePaths.GET_INVOICE,
      operation: ApiOperations.GET,
    },
    {
      label: 'Send Invoice',
      logo: <ArrowOutwardIcon />,
      callback: onSendInvoice,
      path: invoicePaths.SHARE_INVOICE('id'),
      operation: ApiOperations.PUT,
    },
  ];

  const getTableColumns = () => {
    const configColumns: any[] = getColumns(selectedMonth.format('YYYY-MM'));
    const actionColumns: any[] = generateActionColumn(
      rowActions.filter((row) => checkAccess(row.path, row.operation)),
    );
    return [...configColumns, ...actionColumns];
  };

  const getTableRows = (data: any) => {
    const formattedData = data.map((el: any, index: number) => ({ key: index, ...el }));
    return generateRows(formattedData, dynamicColumns, tableRowTotalField, invoiceIdName);
  };

  const getColor = (item: any, index: number, rows: any) => {
    if (item.key === 0) {
      return 'yellow';
    }
    if (rows[index - 1]?.invoiceNumber === item.invoiceNumber) {
      return rows[index - 1]?.color;
    }
    return 'alternate';
  };

  const generateDatatableInputs = () => {
    const columnsList = getTableColumns();
    setTableColumns(columnsList);

    const getInvoiceParams: GetInvoiceParams = {
      start_date: selectedMonth.startOf('month').format('YYYY-MM-DD'),
      end_date: selectedMonth.endOf('month').format('YYYY-MM-DD'),
    };

    setIsLoading(true);

    InvoiceClient.getInvoiceList(getInvoiceParams)
      .then((data) => {
        const result: InvoiceList[] = data.map((row: any) => ({
          ...row,
          invoiceAmount: getConvertedCurrency(row.invoiceAmount, row.projectCurrency),
        }));
        const rows = getTableRows(result);
        rows.map((item, index) => {
          return Object.assign(item, {
            color: getColor(item, index, rows),
          });
        });
        setTableRows(rows);
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    generateDatatableInputs();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [selectedMonth, fetch]);

  const handleSelectionModelChange = (newSelectionModel: GridSelectionModel) => {
    const selectedRowsData = tableRows.filter((row: any) => newSelectionModel.includes(row.id));
    setSelectionModel(selectedRowsData);
    const tempProjectList = selectedRowsData.map((item) => {
      return { value: item.projectUid, label: item.projectName };
    });
    setProjectList(tempProjectList);
  };
  const isRowSelectable = (params: GridRowParams) => {
    if (!isCombineButton) {
      return params.row.invoiceStatus !== InvoiceStatus.FINALISED;
    }
    return true;
  };

  const handleAddClick = () => setIsOpen(!isOpen);

  const handleModalClose = () => setIsOpen(false);
  const generateInvoices = () => {
    setIsLoading(true);
    setShowCombineInvoicesModal(false);
    const submitData = {
      project_uids: projectList.map((item: any) => item.value),
    };
    const primaryProjectUid = formRef.current?.values?.project?.value;
    InvoiceClient.combineInvoices(
      primaryProjectUid,
      selectedMonth.endOf('month').format('YYYY-MM-DD'),
      submitData,
    )
      .then((res) => {
        setProjectList([]);
        setSelectionModel([]);
        setFetch((num) => num + 1);
        showSnackbar(res, 'success');
      })
      .catch((e) => showSnackbar(e, 'error'))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCombineInvoiceModalClose = () => setShowCombineInvoicesModal(false);
  const generateColumnVisibilityModel = (columns: any) => {
    return columns.reduce((acc: any, column: any) => {
      acc[column.field] = true;
      return acc;
    }, {});
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCombineButton(event.target.checked);
    setSelectionModel([]);
  };

  return (
    <Box className={allInvoicesStyles.invoiceWrapper}>
      <Box>
        <Box className={allInvoicesStyles.Header}>
          <span className="headerTitle">
            {intl.formatMessage({
              id: I18nKey.INVOICE_DETAIL_TITLE,
            })}
          </span>
          <Box className={allInvoicesStyles.monthSelectorWrapper}>
            <TextField
              type="month"
              size="small"
              label="Select Month & Year"
              onChange={handleMonthChange}
              defaultValue={
                // eslint-disable-next-line no-nested-ternary
                queryParams.selected_month
                  ? dayjs(queryParams.selected_month as string).format('YYYY-MM')
                  : dayjs().date() <= 5
                  ? dayjs().subtract(1, 'month').format('YYYY-MM')
                  : dayjs().format('YYYY-MM')
              }
              inputProps={{
                min: dayjs('2017').month(3).format('YYYY-MM'),
                max: dayjs().format('YYYY-MM'),
              }}
              className={'monthSelector'}
            />
          </Box>
        </Box>
        <Box className={allInvoicesStyles.allInvoicesWrapper}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ display: 'flex' }}>
              <Typography className="switchTitle">Combine</Typography>
              <Switch
                checked={isCombineButton}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                className="switchBtn"
              />
            </Box>
            {isCombineButton ? (
              <Button
                disabled={!selectionModel.length}
                onClick={handleCombineInvoices}
                variant="contained"
                color="primary"
                type="submit"
                className="downloadAllBtn">
                {intl.formatMessage({
                  id: I18nKey.BUTTON_LABEL_COMBINE_INVOICES,
                })}
              </Button>
            ) : (
              <Button
                disabled={!selectionModel.length}
                onClick={handleDownloadSelected}
                variant="contained"
                color="primary"
                type="submit"
                className="downloadAllBtn">
                {intl.formatMessage({
                  id: I18nKey.BUTTON_LABEL_DOWNLOAD_SELECTED,
                })}
              </Button>
            )}
          </Box>
          <Datatable
            loading={isLoading}
            rows={tableRows}
            columns={tableColumns}
            tableHeight="76vh"
            initialSort={initialSort as any}
            checkboxSelection
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            onSelectionModelChange={handleSelectionModelChange}
            isRowSelectable={isRowSelectable}
            showExportButton
            exportedFileName={`Invoice_List_${dayjs().format('DD_MMMM')}`}
            columnVisibilityModel={generateColumnVisibilityModel(tableColumns)}
            showTotalRowFooter
          />
        </Box>
      </Box>
      <MisDialog
        title={intl.formatMessage({
          id: I18nKey.INVOICE_DETAIL_TITLE,
        })}
        message={'Are you sure you want to send the invoice ?'}
        isOpen={openDialog}
        handleSuccess={onSendInvoiceConfirm}
        handleClose={handleClose}
        additionalInfoSection={
          <Box className={allInvoicesStyles.confirmShareInfoWrapper}>
            <Box className={allInvoicesStyles.infoshareWrapper}>
              To:
              <span className={allInvoicesStyles.infoShareText}> {confirmShareInfo?.toStr}</span>
            </Box>
            <Box className={allInvoicesStyles.infoshareWrapper}>
              CC: <span className={allInvoicesStyles.infoShareText}>{confirmShareInfo?.ccStr}</span>
            </Box>
          </Box>
        }
      />
      <Box
        className={`${allInvoicesStyles.iconbutton} ${allInvoicesStyles.button}`}
        onClick={handleAddClick}>
        <Box className={isOpen ? `${allInvoicesStyles.inverted}` : ''}>
          <AddIcon />
        </Box>
      </Box>
      <MisDialog
        title={intl.formatMessage({ id: I18nKey.COMBINE_INVOICES_MODAL_HEADING })}
        disableSubmitBtn={isProjectFormValid}
        additionalInfoSection={
          <CombineInvoiceForm
            initialField={initialFields}
            setIsProjectFormValid={setIsProjecFormValid}
            formRef={formRef}
            projectList={projectList}
          />
        }
        isOpen={showCombineInvoicesModal}
        handleSuccess={() => {
          generateInvoices();
        }}
        handleClose={handleCombineInvoiceModalClose}
        actionBtnLabel="Generate"
      />
      <Modal open={isOpen} onClose={handleModalClose}>
        <Box>
          <SidePanel header="Upload Invoice" onClose={handleModalClose}>
            <Box className={allInvoicesStyles.formWrapper}>
              <UploadInvoiceForm onSuccess={onSuccessfulInvoiceUpload} />
            </Box>
          </SidePanel>
        </Box>
      </Modal>
    </Box>
  );
};

export default AllInvoices;
