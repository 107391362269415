import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useAllLinkedInStyles = makeStyles((theme: Theme) => {
  return {
    allLinkedInWrapper: {
      height: '100%',
      overflowY: 'auto',
      padding: '1rem',
      backgroundColor: theme.palette.appBackground?.lightest,
      '& .textWrapper': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    tableDetailsWrapper: {
      position: 'relative',
      '& .allBtn': {
        position: 'absolute',
        right: '17.7rem',
        display: 'flex',
        top: 7,
        zIndex: 2,
      },
      '& p': {
        margin: '3px',
        marginTop: '5px',
      },
    },
    Header: {
      fontSize: '14px',
      fontWeight: 'bold',
      display: 'flex',
      '& span': {
        fontSize: '24px',
      },

      '& a': {
        color: theme.palette.text.light,
        textDecoration: 'none',
      },
    },
    filterBox: {
      paddingLeft: '7em',
      marginTop: '-10px',
    },
    buttonWrapper: {
      '& svg': {
        marginRight: '5px',
      },
    },
    linkedlnfilterFormWrapper: {
      padding: '0.7rem',
      width: '840px',
      fontSize: '14px',
    },
    closeIcon: {
      color: '#777 !important',
      float: 'right',
      marginTop: '2px !important',
      marginRight: '2px !important',
      width: '20px !important',
      cursor: 'pointer',
    },
    bottomBox: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '25px',
    },
    removeIconContainer: {
      paddingTop: '10px !important',
    },
    removeIcon: {
      color: 'red !important',
      width: '16px !important',
      cursor: 'pointer',
    },
    addIcon: {
      color: 'green !important',
      width: '16px !important',
      cursor: 'pointer',
    },
    OrIcon: { color: 'black !important', fontSize: '14px !important' },
    AndIcon: { color: 'black !important', width: '10px !important', fontSize: '14px !important' },
    iconsContainer: {
      minHeight: '38px !important',
    },
  };
});

export default useAllLinkedInStyles;
