import { Box, Switch, Tooltip } from '@mui/material';
import { GridCellParams, GridColTypeDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Column } from '../../components/Datatable/DatatableTypes';
import { getColumnConfig } from '../../components/Datatable/datatableUtils';
import { sortAlphaNumeric, replaceEmpty, getAllocationStatus } from '../../utils/utils';
import ExpandableLink from '../../components/ExpandableLink/ExpandableLink';
import CustomMultiLineEdit from '../../components/CustomMultiLineEdit/CustomMultiLineEdit';

const initialSort = { field: 'employeeId', sort: 'asc' };

const dynamicColumns = [{ fieldName: 'project', columnHeader: 'month', rowCell: 'project' }];

const tableRowTotalField: any = [];

const renderCellTooltip = (value: string, length: number = 10) => {
  const newVal = replaceEmpty(value);

  return newVal?.length > length ? (
    <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{newVal}</span>} arrow>
      <div className="MuiDataGrid-cell--textLeft">{newVal}</div>
    </Tooltip>
  ) : (
    <div className="MuiDataGrid-cell--textLeft">{newVal}</div>
  );
};

const calculateDateRange = () => {
  const currentMonthDate = dayjs();
  const nextMonthDate = dayjs().add(1, 'month');

  const dateFrom = currentMonthDate.startOf('month').format('YYYY-MM-DD');
  const dateTo = nextMonthDate.endOf('month').format('YYYY-MM-DD');

  const columnRange: any[] = [];

  return { dateFrom, dateTo, columnRange };
};

const getBillingStatus = (billed: boolean) => {
  switch (billed) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    default:
      return 'NA';
  }
};

const { dateFrom, dateTo, columnRange } = calculateDateRange();

const columns = ({ isExpanded, handleExpanded, handleReviewChange }: any): Column[] => {
  return [
    {
      field: 'employeeId',
      headerName: 'Id',
      width: 62,
      sortable: true,
      filterable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.employeeId),
      sortComparator: sortAlphaNumeric,
    },
    {
      field: 'employeeName',
      type: 'string',
      headerName: 'Name',
      flex: 1.2,
      sortable: true,
      hideable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link to={`/resource/${params.row.id}`} key={params.row.id}>
            {renderCellTooltip(`${params.row.employeeName}`)}
          </Link>
        );
      },
    },
    {
      field: 'employeeDesignation',
      type: 'string',
      headerName: 'Designation',
      hideable: true,
      flex: 1.5,
      sortable: true,
      renderCell: (params: GridRenderCellParams) =>
        renderCellTooltip(params.row.employeeDesignation),
    },
    {
      field: 'managerName',
      type: 'string',
      headerName: 'Manager',
      flex: 1.2,
      sortable: true,
      hideable: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Box className="project-name">
            {params.row.managerName ? renderCellTooltip(`${params.row.managerName}`) : '-'}
          </Box>
        );
      },
    },
    {
      field: 'primarySkills',
      type: 'string',
      headerName: 'Skill(s)',
      flex: 2,
      sortable: true,
      hideable: true,
      hide: true,
      cellClassName: isExpanded ? 'expanded-cell' : 'resource-skill-cell',
      renderCell: (params: GridRenderCellParams) => {
        const cellDisplay = params?.value?.map((skill: string, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="resource-skill" key={skill + index}>
            {skill}
          </div>
        ));
        const renderParams = isExpanded ? cellDisplay : cellDisplay?.slice(0, 2);
        return renderParams?.concat(
          <ExpandableLink {...params} handleExpanded={handleExpanded} isExpanded={isExpanded} />,
        );
      },
    },
    {
      field: 'otherSkills',
      type: 'string',
      headerName: 'Other Skill(s)',
      flex: 1,
      sortable: true,
      hideable: true,
      hide: true,
      cellClassName: 'resource-skill-cell',
      renderCell: (params: GridCellParams) => {
        return params.value
          ? params.value?.map((skill: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="resource-skill project-name" key={skill + index}>
                {renderCellTooltip(skill)}
              </div>
            ))
          : '-';
      },
    },
    {
      field: 'billed',
      type: 'boolean',
      headerName: 'Billed',
      flex: 0.75,
      sortable: true,
      hideable: true,
      renderCell: (params: any) => getBillingStatus(params.value),
    },
    {
      field: 'allocation',
      type: 'number',
      headerName: 'Allocation (%)',
      flex: 0.7,
      sortable: true,
      hideable: true,
      headerAlign: 'left',
      cellClassName: 'left-align-number-field',
      renderCell: (params: GridRenderCellParams) => getAllocationStatus(params.value),
    },
    {
      field: 'primaryProject',
      type: 'string',
      headerName: 'Primary Project',
      flex: 1.7,
      filterable: true,
      sortable: true,
      renderCell: ({ value }: GridRenderCellParams) => {
        return <Box className="project-name">{renderCellTooltip(`${value}`, 10)}</Box>;
      },
    },
    {
      field: 'secondaryProjects',
      type: 'string',
      headerName: 'Secondary Project(s)',
      flex: 1.2,
      filterable: true,
      sortable: true,
      renderCell: ({ value }: GridRenderCellParams) => {
        return <Box className="project-name">{renderCellTooltip(`${value}`, 10)}</Box>;
      },
    },
    ...columnRange.map((field) => {
      return {
        ...getColumnConfig(field),
        flex: 1,
        headerAlign: 'left',
        cellClassName: 'left-align-number-field',
      };
    }),
    {
      field: 'remarks',
      type: 'string',
      headerName: 'Remarks',
      flex: 2,
      hideable: true,
      filterable: false,
      editable: true,
      renderEditCell: (params: any) => <CustomMultiLineEdit {...params} />,
      renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.remarks, 32),
    },
    {
      field: 'opportunity',
      type: 'string',
      headerName: 'Parked',
      flex: 1,
      hideable: true,
      filterable: false,
      editable: true,
      renderCell: (params: GridRenderCellParams) => renderCellTooltip(params.row.opportunity, 16),
    },
    {
      field: 'review',
      type: 'boolean',
      headerName: 'Review',
      flex: 1,
      hideable: true,
      sortable: true,
      filterable: true,
      renderCell: (params) => (
        <Switch
          checked={params.row.review}
          onChange={() => handleReviewChange(params.row.id, params.row.review)}
          size="small"
        />
      ),
    },
    {
      field: 'isInterviewer',
      type: 'string',
      headerName: 'Interviewer',
      flex: 1.5,
      sortable: true,
      hideable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
    {
      field: 'category',
      type: 'string',
      headerName: 'Category',
      flex: 1.5,
      sortable: true,
      hideable: true,
      valueFormatter: (params: any) => replaceEmpty(params.value),
    },
  ];
};

const getInitialVisibilityModel = ({ showColumns }: any) => {
  const colRange = columns({});
  const initialVisibilityModelObj: any = {};
  colRange.forEach((col: any) => {
    initialVisibilityModelObj[col.field] =
      showColumns.includes(dayjs(col.field).month()) || showColumns.includes(col.field);
  });

  return initialVisibilityModelObj;
};

const resourceIdName = 'employeeUid';
const initialShowCols = [
  'employeeId',
  'employeeName',
  'managerName',
  'billed',
  'allocation',
  'primaryProject',
  'remarks',
  'secondaryProjects',
];

export {
  dateFrom,
  dateTo,
  columns,
  initialSort,
  dynamicColumns,
  tableRowTotalField,
  resourceIdName,
  initialShowCols,
  getInitialVisibilityModel,
};
