import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useResourceDetailStyles = makeStyles((theme: Theme) => {
  return {
    card: {
      backgroundColor: theme.palette.appBackground?.lightest,
      borderRadius: '12px',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
    },
    detailsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
      minWidth: '400px',
      padding: '0.5rem',
      backgroundColor: theme.palette.appBackground?.lightest,
      margin: '0 0.5rem',
      borderRadius: '12px',
      marginTop: '23px',
    },
    topContainer: {
      position: 'relative',
      backgroundColor: theme.palette.popUpBackground?.light,
      color: theme.palette.secondary.main,
      borderRadius: '12px',
      padding: '6px',

      '& strong': {
        fontSize: '14px',
        fontWeight: 'bold',
      },
      '& p': {
        fontSize: '12px',
        margin: 0,
      },
      '& button': {
        position: 'absolute',
        top: 0,
        right: 0,
      },
    },
    Card_arrow: {
      marginRight: '10px',
      transform: 'rotate(90deg)',
    },
    Card_arrow_inverted: {
      marginRight: '10px',
      transform: 'rotate(180deg)',
    },
    remarkWrapper: {
      '& .MuiListItemText-root>span': {
        fontSize: '16px',
      },

      '& .text .css-10hburv-MuiTypography-root': {
        fontSize: '12px',
        marginLeft: '4px',
      },
    },
    remark: {
      fontSize: '14px',
      fontWeight: '600',
    },
    bottomContainer: {
      marginTop: '8px',
      position: 'relative',
    },
    formWrapper: {
      minHeight: '265px',
    },
    formContainer: {
      marginTop: '10px',
      padding: '0 8px 8px 8px',
      display: 'flex',
      flexDirection: 'column',
    },
    addBtn: {
      '& svg': {
        marginRight: '5px',
      },
    },
    formField: {
      '& input': {
        height: '18px',
      },
    },
    bottomContainerTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    bottomContainerTitle: {
      color: theme.palette.secondary.main,
      fontSize: '12px',
      fontWeight: '600',
      padding: '6px 8px',
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '300px',
    },
    primaryCheck: {
      '& span': {
        fontSize: 13,
      },
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    managerFields: { padding: '0.6rem 0.5rem 0.5rem 0.5rem' },
    inputFont: { fontSize: 13 },
    resourceTabWrapper: {
      '& .MuiTabs-root': {
        minHeight: '32px',
      },

      '& .MuiTabs-flexContainer > button ': {
        padding: '0 !important',
        minHeight: '32px',
      },
    },
    deleteBtn: {
      color: `${theme.palette.status?.danger} !important`,
      fontSize: '13px !important',
      marginTop: '-35px !important',
      paddingLeft: '1px !important',
    },
    projectWrapper: {
      display: 'flex',
      fontSize: '14px',
      marginBottom: '4px',
      color: theme.palette.text.disabled,

      '& .MuiTypography-root': {
        marginRight: '5px',
      },
    },
    toggleButtonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    categoryContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    spinnerWrapperInterviewer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '55px',
    },
    goBackWrapper: {
      textAlign: 'right',
    },
    goBackLink: {
      color: `${theme.palette.secondary.main} !important`,
      fontSize: '14px !important',
      marginRight: '7px !important',
      textDecoration: 'none !important',
    },
    expandedCard: {
      backgroundColor: theme.palette.appBackground?.lightest,
      borderRadius: '12px',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      marginTop: '10px',
    },
    noProjectHistoryText: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      color: `grey`,
      fontSize: '1rem',
    },
    showAlloCationHistoryBtn: {
      color: `${theme.palette.secondary.main} !important`,
      fontSize: '13px !important',
      fontWeight: '600 !important',
      margin: '7px !important',
      textDecoration: 'underline !important',
      textAlign: 'left',
    },
    historyTableWrapper: {
      marginTop: '6px',
      '& .project-name': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    dateInput: {
      padding: '4px !important',
      borderRadius: '4px !important',
      fontSize: '10px !important',
      height: '20px !important',
      marginRight: '10px',
      marginBottom: '10px',
    },
    fetchResultsButton: {
      fontSize: '12px',
      marginRight: '2px !important',
      align: 'right',
    },
  };
});

export default useResourceDetailStyles;
