import axios from 'axios';
import queryString from 'query-string';
import axiosInstance from '../axiosInstance';
import {
  GetInvoiceParams,
  InvoiceList,
  InvoiceDetail,
  GenerateInvoiceParams,
  ShareInvoiceParams,
  InvoiceStatus,
  GetInvoiceStatusParams,
  CreateOtherDetailsParams,
} from './invoiceTypes';

export const PATHS = Object.freeze({
  GET_INVOICE: `/invoice`,
  GET_INVOICE_STATUS: (projectId: string) => `/invoice/${projectId}/status`,
  UPLOAD_INVOICE: (projectId: string) => `/invoice/${projectId}/manual`,
  GET_OTHER_DETAILS: (id: string) => `/invoice/${id}/billing`,
  CREATE_OTHER_DETAILS: (id: string) => `/invoice/${id}/billing`,
  OTHER_DETAILS: (date: string) => `/invoice/billing/?date=${date}`,
  GENERATE_INVOICE: (projectId: string, date: string) => `/invoice/${projectId}/generate/${date}`,
  SHARE_INVOICE: (invoiceUid: string) => `/invoice/${invoiceUid}/share`,
  GET_EMAIL: (invoiceUid: string) => `/invoice/${invoiceUid}/recipients`,
  COMBINE_INVOICES: (primary_project_uid: string, date: string) =>
    `/invoice/consolidated/${primary_project_uid}/add/${date}`,
});

const getInvoiceList = async (getInvoiceParams: GetInvoiceParams): Promise<InvoiceList[]> => {
  const queryParams = queryString.stringify(getInvoiceParams);

  const response = await axiosInstance.get(`${PATHS.GET_INVOICE}/?${queryParams}`);
  return response.data;
};

const getInvoiceDetail = async (getInvoiceParams: GetInvoiceParams): Promise<InvoiceDetail> => {
  const queryParams = queryString.stringify(getInvoiceParams);

  const response = await axiosInstance.get(`${PATHS.GET_INVOICE}/?${queryParams}`);
  return response.data;
};

const getInvoiceStatus = async (
  projectId: string,
  getInvoiceParams: GetInvoiceStatusParams,
): Promise<any> => {
  const queryParams = queryString.stringify(getInvoiceParams);

  const response = await axiosInstance.get(
    `${PATHS.GET_INVOICE_STATUS(projectId)}/?${queryParams}`,
  );
  return response.data;
};

const generateInvoice = async (
  projectId: string,
  date: string,
  params: GenerateInvoiceParams | {},
): Promise<any> => {
  const response = await axiosInstance.post(PATHS.GENERATE_INVOICE(projectId, date), params);
  return response;
};

const shareInvoice = async (params: ShareInvoiceParams): Promise<any> => {
  const response = await axiosInstance.put(PATHS.SHARE_INVOICE(params.invoiceUid));
  return response.data;
};

const downloadInvoice = async (params: any): Promise<any> => {
  const response = await axios.get<string, any>(params.preSignedUrl, {
    responseType: 'arraybuffer',
  });
  return response;
};

const getInvoiceOtherDetails = async (
  getInvoiceOtherDetailsParams: GetInvoiceParams,
): Promise<any> => {
  const queryParams = queryString.stringify(getInvoiceOtherDetailsParams);

  const response = await axiosInstance.get(
    `${PATHS.GET_OTHER_DETAILS(getInvoiceOtherDetailsParams.project_uid || '')}/?${queryParams}`,
  );
  return response.data;
};

const createOtherDetail = async (params: CreateOtherDetailsParams, payload: any): Promise<any> => {
  const queryParams = queryString.stringify(params);
  const response = await axiosInstance.post(
    `${PATHS.CREATE_OTHER_DETAILS(params.project_uid)}/?${queryParams}`,
    payload,
  );
  return response;
};

const combineInvoices = async (
  primary_project_uid: string,
  date: string,
  payload: any,
): Promise<any> => {
  const response = await axiosInstance.post(
    `${PATHS.COMBINE_INVOICES(primary_project_uid, date)}`,
    payload,
  );
  return response;
};

const updateOtherDetail = async (payload: any, updateDate: string): Promise<any> => {
  const response = await axiosInstance.put(PATHS.OTHER_DETAILS(updateDate), payload);
  return response;
};

const deleteOtherDetail = async (data: string[], deleteDate: string): Promise<any> => {
  const response = await axiosInstance.delete(PATHS.OTHER_DETAILS(deleteDate), {
    data,
  });
  return response.data;
};

const createManualInvoice = async (params: any, project_uid: string): Promise<any> => {
  const response = await axiosInstance.post(PATHS.UPLOAD_INVOICE(project_uid), params);
  return response;
};

const updateManualInvoice = async (payload: any, project_uid: string): Promise<any> => {
  const response = await axiosInstance.put(PATHS.UPLOAD_INVOICE(project_uid), payload);
  return response;
};

const getEmailRecipients = async (invoiceUid: string): Promise<any> => {
  const response = await axiosInstance.get(PATHS.GET_EMAIL(invoiceUid));
  return response.data;
};

export interface InvoiceAPIClient {
  readonly getInvoiceList: (getInvoiceParams: GetInvoiceParams) => Promise<InvoiceList[]>;
  readonly getInvoiceDetail: (getInvoiceParams: GetInvoiceParams) => Promise<InvoiceDetail>;
  readonly getInvoiceStatus: (
    projectId: string,
    getInvoiceParams: GetInvoiceStatusParams,
  ) => Promise<InvoiceStatus>;
  readonly generateInvoice: (
    projectId: string,
    date: string,
    params: GenerateInvoiceParams | {},
  ) => Promise<any>;
  readonly shareInvoice: (params: ShareInvoiceParams) => Promise<any>;
  readonly downloadInvoice: (params: any) => Promise<any>;
  readonly getInvoiceOtherDetails: (params: GetInvoiceParams) => Promise<any>;
  readonly createOtherDetail: (params: CreateOtherDetailsParams, payload: any) => Promise<any>;
  readonly updateOtherDetail: (payload: any, updateDate: string) => Promise<any>;
  readonly deleteOtherDetail: (data: string[], deleteDate: string) => Promise<any>;
  readonly createManualInvoice: (params: any, project_uid: string) => Promise<any>;
  readonly updateManualInvoice: (payload: any, project_uid: string) => Promise<any>;
  readonly getEmailRecipients: (invoiceUid: string) => Promise<any>;
  readonly combineInvoices: (
    primary_project_uid: string,
    date: string,
    payload: any,
  ) => Promise<any>;
}

const InvoiceClient: InvoiceAPIClient = Object.freeze({
  getInvoiceList,
  getInvoiceDetail,
  getInvoiceStatus,
  generateInvoice,
  shareInvoice,
  downloadInvoice,
  getInvoiceOtherDetails,
  createOtherDetail,
  updateOtherDetail,
  deleteOtherDetail,
  createManualInvoice,
  updateManualInvoice,
  getEmailRecipients,
  combineInvoices,
});

export default InvoiceClient;
