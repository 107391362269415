import { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import queryString from 'query-string';
import SimpleInputField from '../../components/SimpleInputField/SimpleInputField';
import { GlobalContext } from '../../contexts/GlobalContext';
import I18nKey from '../../translations/I18nKey';
import useDisplaySnackbar from '../../utils/useDisplaySnackbar';
import ProgressSpinner from '../../components/ProgressSpinner/ProgressSpinner';
import { addOffsiteDetialsFormValidation, getUpdatedInfo } from './utils';
import useAddOffsiteStyles from './AddOffsiteDetailsStyles';
import OffsiteClient from '../../api/Offsite/OffsiteAPIs';
import SimpleAutocompleteField from '../../components/SimpleAutocompleteField/SimpleAutocompleteField';
import maleTshirtSizeChart from '../../assets/icons/maleTshirtSizeChart.png';
import femaleTshirtSizeChart from '../../assets/icons/femaleTshirtSizeChart.png';

const AddOffsiteDetails: React.FC<any> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { employeeUid } = useContext(GlobalContext);
  const intl = useIntl();
  const { showSnackbar } = useDisplaySnackbar();
  const styles = useAddOffsiteStyles();
  const [initialValues, setInitialValues] = useState<any>({
    firstName: '',
    lastName: '',
    available: 'yes',
    inPune: '',
    travelRequired: '',
    accomodationRequired: '',
    reason: '',
    tshirt_size: '',
    medicalIssues: '',
  });
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const [viewMode, setViewMode] = useState(queryParams.viewMode);
  const [gender, setGender] = useState('');
  const sizeOptions = [
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
    { value: 'L', label: 'L' },
    { value: 'XL', label: 'XL' },
    { value: '2XL', label: '2XL' },
    { value: '3XL', label: '3XL' },
  ];
  const setRadioButton = (value: boolean) => {
    return value ? 'yes' : 'no';
  };

  const getEmployeeDetails = () => {
    setIsLoading(true);
    OffsiteClient.getEmployeeDetails(employeeUid)
      .then((res: any) => {
        if (res?.submitted && viewMode !== 'false') {
          navigate(`/offsite/viewMode/${id}`);
        }
        let setInitialInfo;
        if (!res?.submitted) {
          setGender(res.employeeGender);
          setInitialInfo = {
            firstName: res.employeeFirstName,
            lastName: res.employeeLastName,
            available: res.availableForOffsite || 'yes',
            inPune: res.isNearPune,
            travelRequired: res.isTravelRequired,
            accomodationRequired: res.isAccommodationRequired,
            reason: res.reasonForNotAttending,
            tshirt_size: sizeOptions.find((v) => v.value === res.tshirtSize) || null,
            medicalIssues: res.medicalIssues,
          };
        } else {
          setGender(res.employeeGender);
          setInitialInfo = {
            firstName: res.employeeFirstName,
            lastName: res.employeeLastName,
            available: setRadioButton(res.availableForOffsite),
            inPune: setRadioButton(res.isNearPune),
            travelRequired: setRadioButton(res.isTravelRequired),
            accomodationRequired: setRadioButton(res.isAccommodationRequired),
            reason: res.reasonForNotAttending,
            tshirt_size: sizeOptions.find((v) => v.value === res.tshirtSize) || null,
            medicalIssues: res.medicalIssues,
          };
        }
        setInitialValues({ ...setInitialInfo });
      })
      .catch((e: any) => {
        showSnackbar(e, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getPayload = (values: any) => {
    if (values.available === 'yes') {
      return {
        available_for_offsite: values.available,
        reason_for_not_attending: '',
        is_near_pune: values.inPune,
        is_travel_required: values.travelRequired,
        is_accommodation_required: values.accomodationRequired,
        tshirt_size: values.tshirt_size?.value || null,
        medical_issues: values.medicalIssues || null,
      };
    }
    return {
      available_for_offsite: values.available,
      reason_for_not_attending: values?.reason || '',
      tshirt_size: values.tshirt_size?.value || null,
      medical_issues: null,
    };
  };

  const handleSubmit = (values: any) => {
    setIsLoading(true);
    const payload = getPayload(values);
    OffsiteClient.addOffsiteDetails(payload, employeeUid || '')
      .then((res: any) => {
        showSnackbar(res, 'success');
        setViewMode('');
      })
      .catch((e: any) => showSnackbar(e, 'error'))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getEmployeeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode]);
  return (
    <>
      {isLoading && <ProgressSpinner showSpinner={isLoading} />}
      <Box className={styles.addFamilyWrapper}>
        <Box className={styles.Header}>
          <span className="headerTitle">
            {intl.formatMessage({
              id: I18nKey.OFFSITE_TITLE,
            })}
          </span>
        </Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={addOffsiteDetialsFormValidation}
          enableReinitialize
          validateOnMount>
          {(formik) => {
            const { values, setFieldValue, isValid, handleBlur } = formik;
            return (
              <Form className={styles.formWrapper}>
                <Grid container rowSpacing={2} spacing={1}>
                  <Grid item xs={12}>
                    <Divider>
                      <Chip label="Employee Details" className="divider-chip" />
                    </Divider>
                  </Grid>
                  <Grid item xs={2.4}>
                    <SimpleInputField
                      disabled
                      name="firstName"
                      type="string"
                      label="First Name"
                      size="small"
                      onBlur={(event: any) => {
                        handleBlur(event);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={2.4}>
                    <SimpleInputField
                      disabled
                      name="lastName"
                      type="string"
                      label="Last Name"
                      size="small"
                      onBlur={(event: any) => {
                        handleBlur(event);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider>
                      <Chip label="Offsite Details" className="divider-chip" />
                    </Divider>
                  </Grid>

                  <Grid item xs={12}>
                    <Box className={styles.checkboxWrapper}>
                      <FormControl
                        sx={{
                          marginTop: '10px',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        <FormLabel sx={{ marginRight: '10px', fontSize: '14px' }}>
                          Are you available for offsite?
                        </FormLabel>

                        <RadioGroup
                          row
                          value={values.available}
                          onBlur={handleBlur}
                          onChange={(event, value) => setFieldValue('available', value)}
                          name="available"
                          sx={{ fontSize: '14px' }}>
                          <FormControlLabel value="yes" control={<Radio />} label="Yes" disabled />
                          <FormControlLabel value="no" control={<Radio />} label="No" disabled />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      border: '1px solid #d2d2d2',
                      borderRadius: '4px',
                      display: 'flex',
                      height: 'max-content',
                      padding: '10px 8px 16px 8px',
                      margin: '10px',
                      minWidth: '80%',
                    }}>
                    {values.available === 'no' && (
                      <Grid item xs={7}>
                        <Grid xs={6} container>
                          <Grid item xs={12}>
                            <FormLabel style={{ width: '50%', fontSize: '14px' }}>
                              Please provide a reason:-
                            </FormLabel>
                            <SimpleInputField
                              name="reason"
                              type="string"
                              label="Reason"
                              size="small"
                              onBlur={(event: any) => {
                                handleBlur(event);
                              }}
                              fullWidth
                              multiline
                              minRows={2}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {values.available === 'yes' && (
                      <Grid item xs={7}>
                        <Grid xs={6} container>
                          <Grid item xs={12}>
                            <Box className={styles.checkboxWrapper}>
                              <FormControl
                                sx={{
                                  marginTop: '10px',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}>
                                <FormLabel sx={{ marginRight: '10px', fontSize: '14px' }}>
                                  Are you in or around Pune?
                                </FormLabel>
                                <RadioGroup
                                  row
                                  value={values.inPune}
                                  onBlur={handleBlur}
                                  onChange={(event, value) => setFieldValue('inPune', value)}
                                  name="inPune"
                                  sx={{ fontSize: '14px' }}>
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label="Yes"
                                    disabled
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                    disabled
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box className={styles.checkboxWrapper}>
                              <FormControl
                                sx={{
                                  marginTop: '10px',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  fontSize: '14px',
                                }}>
                                <FormLabel sx={{ marginRight: '10px', fontSize: '14px' }}>
                                  Do you need travel assistance?
                                </FormLabel>
                                <RadioGroup
                                  row
                                  value={values.travelRequired}
                                  onBlur={handleBlur}
                                  onChange={(event, value) =>
                                    setFieldValue('travelRequired', value)
                                  }
                                  name="travelRequired"
                                  sx={{ fontSize: '14px' }}>
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label="Yes"
                                    disabled
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                    disabled
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box className={styles.checkboxWrapper}>
                              <FormControl
                                sx={{
                                  marginTop: '10px',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  fontSize: '14px',
                                }}>
                                <FormLabel sx={{ marginRight: '10px', fontSize: '14px' }}>
                                  {"Do you need company's accomodation?"}
                                </FormLabel>
                                <RadioGroup
                                  row
                                  value={values.accomodationRequired}
                                  onBlur={handleBlur}
                                  onChange={(event, value) =>
                                    setFieldValue('accomodationRequired', value)
                                  }
                                  name="accomodationRequired"
                                  sx={{ fontSize: '14px' }}>
                                  <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label="Yes"
                                    disabled
                                  />
                                  <FormControlLabel
                                    value="no"
                                    control={<Radio />}
                                    label="No"
                                    disabled
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={12} mt={2}>
                            <FormLabel style={{ width: '50%', fontSize: '14px' }}>
                              Health Problems/Recent Chronic illness
                            </FormLabel>
                            <SimpleInputField
                              name="medicalIssues"
                              type="string"
                              size="small"
                              onBlur={(event: any) => {
                                handleBlur(event);
                              }}
                              fullWidth
                              multiline
                              minRows={2}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    <Grid item xs={3}>
                      <SimpleAutocompleteField
                        disabled
                        name="tshirt_size"
                        key="tshirt_size"
                        label="Select Tshirt Size"
                        size="small"
                        setFieldValue={setFieldValue}
                        options={sizeOptions}
                        values={values}
                        fullWidth
                      />
                      {gender === 'Male' && (
                        <Grid item xs={12} sx={{ marginTop: '10px' }}>
                          <Box
                            component="img"
                            src={maleTshirtSizeChart}
                            alt="Male Tshirt Size Chart"
                          />
                        </Grid>
                      )}
                      {gender === 'Female' && (
                        <Grid item xs={12} sx={{ marginTop: '10px' }}>
                          <Box
                            component="img"
                            src={femaleTshirtSizeChart}
                            alt="Female Tshirt Size Chart"
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {/* disabled={!isValid} */}
                <Button
                  disabled={!isValid}
                  variant="contained"
                  type="submit"
                  className={styles.submitBtn}>
                  {intl.formatMessage({
                    id: I18nKey.BUTTON_LABEL_SUBMIT,
                  })}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default AddOffsiteDetails;
