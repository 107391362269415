import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme: Theme) => {
  return {
    dataTableWrapper: {
      fontSize: '0.9rem !important',
      '& .left-align-number-field': {
        justifyContent: 'flex-start !important',
      },
      '& .right-align-number-field': {
        display: 'flex',
        justifyContent: 'flex-end !important',
      },
      '& .delete-action': {
        visibility: 'hidden',
      },
      // TODO: check tooltip positioning
      '.MuiDataGrid-panel': {
        top: '-60px !important',
        transform: 'translate3d(1030px, 229px, 0px) !important',
      },
      '& .highlighted-row': {
        backgroundColor: '#ffcbcb	!important',
      },
      '& .highlighted-clone-row': {
        backgroundColor: '#FFF3E6	!important',
        boxShadow: '1px 2px 3px #c2c2c2 !important',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
      },
      '& .highlighted-alternate-row': {
        backgroundColor: '#F5F5DC	!important',
        boxShadow: '1px 2px 3px #c2c2c2 !important',
        borderTop: '1px solid rgba(224, 224, 224, 1)',
      },
      '& .commented-cell': {
        position: 'relative',
      },
      '& .commented-cell:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        borderStyle: 'solid',
        borderWidth: '0 10px 10px 0',
        borderColor: 'transparent #FB7272 transparent transparent',
      },
      '& .MuiDataGrid-toolbarContainer .MuiButton-root': {
        textTransform: 'capitalize',
        fontSize: theme.typography.fontSize,
        paddingBottom: '5px',
      },
      '& .MuiDataGrid-root': {
        border: 'none !important',
      },
      '& .MuiDataGrid-toolbarContainer': {
        paddingBottom: '5px',
      },
      '& .MuiDataGrid-columnHeaders': {
        border: '1px solid rgba(224, 224, 224, 1)',
        boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
        minHeight: '52px !important',
        height: '52px !important',
      },
      '& .MuiDataGrid-row': {
        minHeight: '42px !important',
        boxShadow: `0px 0px 2px ${theme.palette.shadow?.main}`,
      },
      '& .MuiDataGrid-actionsCell': {
        gridGap: '0px !important',
      },
      '& .MuiDataGrid-columnSeparator, .MuiDataGrid-menuIcon': {
        display: 'none !important',
      },
      '& .MuiDataGrid-footerContainer': {
        display: 'flex',
        justifyContent: 'left',
      },
    },
    editIcon: {
      '& svg': {
        color: theme.palette.secondary.main,
      },
    },
    disabledEditIcon: {
      '& svg': {
        color: '#bdbdbd',
      },
    },
    search: {
      justifyContent: 'flex-end',
      marginLeft: '10px',
      width: '300px',
    },
    colCell: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    customInputFieldWrapper: {
      padding: 10,
    },
    customInputField: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield',
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    },
    noRowsWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    footerRowWrapper: {
      position: 'relative',
      marginLeft: '-17px',
      width: 'calc(100vw - 7px)',
      left: 0,
      bottom: 17,
    },
    footerRow: {
      background: theme.palette.primary.main,
      display: 'flex',
      borderRadius: '8px',
      margin: '1.1rem',
      boxShadow: `0px 0px 4px ${theme.palette.shadow?.main}`,
      '& .footerCell': {
        height: '48px !important',
        padding: '0 6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end !important',
        overflow: 'hidden',

        '& span': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      '& .footerCell:first-child': {
        justifyContent: 'flex-start !important',
      },
    },
    pageNumberWrapper: {
      position: 'relative',
      bottom: '44px',
      left: '250px',
      width: '80px',
      '&.pageRowSelected': {
        left: '360px',
      },
    },
    pageNumberField: {
      '& input': {
        height: '14px',
      },
    },
  };
});
